/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import axiosConfig from 'utils/axiosConfig';

import { Calendar } from 'primereact/calendar';
import { checkInputMissingExceptCommentAndWorkStepId } from 'utils/utils';
import {
  downloadQRCode,
  generateQRCode,
  showQR,
  QRCodeTemplate,
  showBarcode,
  generateBarcodePNG,
  BarcodeCodeTemplate,
} from 'elements/QRGeneration';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { formatDate } from 'pages/Supplier/Supplier';

function EggLogistics() {
  const toast = useContext(ToastContext);

  const [itemList, setItemList] = useState(null);

  const [visible, setVisible] = useState(false);

  const [QR, setQR] = useState();

  const [barcode, setBarcode] = useState();

  const [loadingEggInfo, setLoadingEggInfo] = useState(false);

  const [eggInformation, setEggInformation] = useState([]);

  const [item, setItem] = useState({
    eggId: '',
    productionDate: new Date(),
    deliveryDate: new Date(),
    deliveryAmount: 0,
    bestBefore: new Date(),
    comment: '',
    // supplier: '',
  });

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const tableDataJSON = await axiosConfig.get('/eggLogistics/table');
        setItemList(tableDataJSON.data);
      } catch (error) {
        console.error(error);
        toast.pushToast({
          severity: ToastSeverity.ERROR,
          detail: 'Error while fetching Data',
        });
      }
    };

    fetchTableData();
    item?.eggId && generateQRCode(item.eggId, (QRTemp) => setQR(QRTemp));
    item?.eggId &&
      generateBarcodePNG(item.eggId, (barcodeTemp) => setBarcode(barcodeTemp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const generateEggId = async () => {
    const eggIdFree = await axiosConfig
      .get(`/eggLogistics/unusedID`)
      .then((res) => res.data);
    setItem({ ...item, eggId: eggIdFree });
  };

  const handleFocus = (event) => event.target.select();

  // can be made into one-liner!
  const resetForm = () => {
    setItem({
      ...item,
      eggId: '',
      productionDate: '',
      deliveryDate: '',
      bestBefore: '',
      comment: '',
    });
  };

  const sendEgg = async (egg) => {
    try {
      if (egg !== item.eggId) {
        toast.pushToast({
          severity: ToastSeverity.WARN,
          detail: 'Scanned EggID does not match InputMask!',
        });
      }
      await axiosConfig.post('/eggLogistics/create', {
        ...item,
        eggId: egg,
      });

      resetForm();

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful Egg Delivery',
      });
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.statusText,
      });
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span className={`charge-badge charge-badge-${rowData.Status}`}>
        {rowData.Status}
      </span>
    );
  };

  const fetchEggInformation = async (eggId) => {
    setLoadingEggInfo(eggId);
    return axiosConfig
      .get(`/eggLogistics/eggInformation`, { params: { eggId } })
      .then((res) => {
        setLoadingEggInfo(false);
        setEggInformation({
          eggId,
          array: [
            {
              label: 'Best Before',
              value: formatDate(new Date(res.data.bestBefore)),
            },
            {
              label: 'Delivery Date',
              value: formatDate(new Date(res.data.deliveryDate)),
            },
            {
              label: 'Production Date',
              value: formatDate(new Date(res.data.productionDate)),
            },
            {
              label: 'Comment',
              value: res.data.comment,
            },
          ],
        });
      });
  };

  const eggInformationDialog = () => (
    <Dialog
      header={`Egg Information for ${eggInformation.eggId}`}
      visible={visible === 'eggInformation' && !loadingEggInfo}
      onHide={() => setVisible(false)}
    >
      <DataTable value={!loadingEggInfo ? eggInformation.array : []}>
        <Column key="label" field="label" />
        <Column key="value" field="value" />
      </DataTable>
    </Dialog>
  );

  const showEggInformation = (eggId) => {
    return (
      <Button
        icon="pi pi-ellipsis-v"
        className="p-button-text mr-2 "
        text
        loading={loadingEggInfo === eggId}
        onClick={() =>
          fetchEggInformation(eggId).then(setVisible('eggInformation'))
        }
      />
    );
  };

  return (
    <div className="main-card main-card-content ">
      {visible === 'QR' &&
        QR &&
        showQR({
          QR,
          visible: visible === 'QR',
          setVisible,
        })}
      {visible === 'eggInformation' && eggInformationDialog()}
      {visible === 'barcode' &&
        barcode &&
        showBarcode({
          barcode,
          visible: visible === 'barcode',
          setVisible,
        })}
      <div className="mt-2 mb-5">
        <h2>Egg Logistics</h2>
      </div>
      <div className="grid ">
        <div className="col-5">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h3>Egg Delivery</h3>
            </div>
            <div className="grid">
              <div className="col-12">
                <h4>1. Generate or Enter Egg ID </h4>
                <div className="grid">
                  <div className=" col-6">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 mb-2"
                      onClick={() => generateEggId()}
                      label="Generate"
                    />
                  </div>
                  <div className="col-6">
                    <InputMask
                      mask="EG-99999"
                      value={item.eggId}
                      type="text"
                      placeholder="EG-"
                      onChange={(e) =>
                        setItem({ ...item, eggId: e.target.value })
                      }
                      keyfilter="pint"
                      // disabled
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <h4>2. Add Metainformation</h4>
                <div className="formgrid grid align-items-end">
                  <div className="field col-6">
                    <label htmlFor="productionDate">Production Date</label>
                    <Calendar
                      dateFormat="dd.mm.yy"
                      value={item.productionDate}
                      onChange={(e) =>
                        setItem({ ...item, productionDate: e.value })
                      }
                      onFocus={handleFocus}
                    />
                  </div>

                  <div className="field col-6">
                    <label htmlFor="deliverydate">Delivery Date</label>
                    <Calendar
                      dateFormat="dd.mm.yy"
                      value={item.deliveryDate}
                      onChange={(e) =>
                        setItem({ ...item, deliveryDate: e.value })
                      }
                      onFocus={handleFocus}
                    />
                  </div>

                  <div className="field col-6">
                    <label htmlFor="bestBefore">Best Before</label>
                    <Calendar
                      dateFormat="dd.mm.yy"
                      value={item.bestBefore}
                      onChange={(e) =>
                        setItem({ ...item, bestBefore: e.value })
                      }
                      onFocus={handleFocus}
                    />
                  </div>

                  <div className="field col-6">
                    <label htmlFor="deliveryamount">Delivery Amount</label>
                    <div className="p-inputgroup">
                      <InputNumber
                        value={item.deliveryAmount}
                        id="deliveryamount"
                        type="text"
                        onChange={(e) =>
                          setItem({ ...item, deliveryAmount: e.value })
                        }
                        mode="decimal"
                        locale="de-DE"
                        minFractionDigits={1}
                        onFocus={handleFocus}
                      />
                      <span
                        style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                        className="p-inputgroup-addon"
                        title="Weight per sieving"
                      >
                        kg
                      </span>
                    </div>
                  </div>
                  <div className="field col-12">
                    <label htmlFor="comment">Comment</label>
                    <InputTextarea
                      autoResize
                      value={item.comment}
                      id="comment"
                      type="text"
                      onChange={(e) =>
                        setItem({ ...item, comment: e.target.value })
                      }
                      col={12}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12  ">
                <h4>2. Print QR Code </h4>
                <div className="grid">
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Show QR"
                      onClick={() => setVisible('QR')}
                      disabled={
                        (!item?.eggId || item?.eggId.includes('_')) && !barcode
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Show Barcode"
                      onClick={() => setVisible('barcode')}
                      disabled={
                        (!item?.eggId || item?.eggId.includes('_')) && !barcode
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Print QR"
                      onClick={() => QR && downloadQRCode(QR, item?.eggId)}
                      disabled={
                        (!item?.eggId || item?.eggId.includes('_')) && !barcode
                      }
                    />
                  </div>
                  <div className="col-3">
                    <Button
                      className="p-button-rounded p-button-outlined mr-2 "
                      label="Print Barcode"
                      onClick={() =>
                        barcode && downloadQRCode(barcode, item?.eggId)
                      }
                      disabled={
                        (!item?.eggId || item?.eggId.includes('_')) && !barcode
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <h4>4. Create Egg Delivery </h4>
                <Button
                  className="p-button-success p-button-rounded"
                  label="Create egg delivery"
                  onClick={() =>
                    sendEgg(item.eggId).then(() =>
                      setItem({
                        eggId: '',
                        productionDate: new Date(),
                        deliveryDate: new Date(),
                        deliveryAmount: '',
                        bestBefore: new Date(),
                        comment: '',
                        // supplier: '',
                      })
                    )
                  }
                  disabled={
                    item.eggId.includes('_') ||
                    checkInputMissingExceptCommentAndWorkStepId(item)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-7">
          <div className="card-content p-fluid">
            <div className="mt-2 mb-5">
              <h3>Egg Overview</h3>
            </div>

            <DataTable
              value={itemList}
              showGridlines
              scrollable
              scrollHeight="50vh"
            >
              <Column field="eggId" header="Egg ID" sortable />
              <Column
                field="remainingAmount"
                header="Remaining Amount [kg]"
                style={{ maxWidth: '12rem' }}
                sortable
              />
              <Column
                field="Status"
                header="Status"
                body={statusBodyTemplate}
                sortable
              />
              <Column
                header="QR"
                body={(rowData) => QRCodeTemplate(rowData.eggId)}
              />
              <Column
                header="Barcode"
                body={(rowData) => BarcodeCodeTemplate(rowData.eggId)}
              />
              <Column
                header="Info"
                body={(rowData) => showEggInformation(rowData.eggId)}
              />
            </DataTable>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default EggLogistics;
