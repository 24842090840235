/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';
import { Dialog } from 'primereact/dialog';
import axiosConfig from 'utils/axiosConfig';
import { Fieldset } from 'primereact/fieldset';

import { formatDate } from 'pages/Supplier/Supplier';
import { MILLISECONDSINADAY } from 'utils/Constants';
import { FoodDeliveryContext } from './FoodContext';
import FoodAssignDelivery from './FoodCreateDeliveries';
import FoodAddIngredients from './FoodAddIngredients';
import FoodCreateConfirmation from './FoodCreateConfirmation';

const EPSILON = 0.1;

const isEqual = (a, b) => {
  return Math.abs(a - b) < EPSILON;
};

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function CreateFood({ data, display, onClose, onCompletion }) {
  const toast = useContext(ToastContext);

  const initItem = {
    foodName: '',
    productionDate: new Date(),
    bestBefore: new Date(),
    comment: '',
    recipeId: null,
    targetWeight: 0.0,
  };

  const [inComplete, setInComplete] = useState(true);

  const [recipeList, setRecipeList] = useState([]);

  const [action, setAction] = useState(null);

  const [ingredientList, setIngredientList] = useState([]);

  const [expandedRows, setExpandedRows] = useState(null);

  const [currentItem, setCurrentItem] = useState(initItem);

  const [newRecipeID, setNewRecipeID] = useState(null);

  const [oldestDelivery, setOldestDelivery] = useState();

  const [totalAssignedWeight, setTotalAssignedWeight] = useState(0);

  const [incompleteIngredientList, setIncompleteIngredientList] =
    useState(true);

  const [item, setItem] = useState(initItem);
  const [foodId, setFoodId] = useState(null);

  const providerValue = useMemo(
    () => ({ ingredientList, setIngredientList }),
    [ingredientList, setIngredientList]
  );

  useEffect(() => {
    fetchFoodTable();
    fetchRecipeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Recalculate the assigned and total assigned weights after a change within the assigned deliveries
    const ingredientListTemp = ingredientList;
    if (ingredientListTemp?.length > 0) {
      let currentTotalAssignedWeight = 0.0;

      ingredientListTemp?.map((ingredient, index) => {
        let ingredientAssignedWeight = 0.0;
        let numberOfAssignedDeliveries = 0;
        ingredient?.assignedDeliveries?.map((assignedDeliveries) => {
          ingredientAssignedWeight += Number(assignedDeliveries.assignedAmount);
          numberOfAssignedDeliveries += 1;
        });
        ingredientListTemp[index].assignedWeight = ingredientAssignedWeight;
        ingredientListTemp[index].numberOfAssignedDeliveries =
          numberOfAssignedDeliveries;
        currentTotalAssignedWeight += ingredientAssignedWeight;
      });

      setTotalAssignedWeight(currentTotalAssignedWeight);
      setIngredientList(ingredientListTemp);
    }
  }, [ingredientList]);

  useEffect(() => {
    let tempInComplete = false;
    for (const key of Object.keys(item)) {
      if (
        key !== 'comment' &&
        (item[key] === null ||
          item[key] === undefined ||
          item[key] === '' ||
          item[key] === 0)
      ) {
        tempInComplete = true;
      }
    }
    setInComplete(tempInComplete);
    if (
      (item !== initItem && currentItem === initItem) ||
      (item.recipeId !== null &&
        item.recipeId !== newRecipeID &&
        item.recipeId !== currentItem.recipeId)
    ) {
      fetchIngredientListForRecipe(item.recipeId);
    }

    if (
      item.recipeId === newRecipeID &&
      item.recipeId !== currentItem.recipeId
    ) {
      setIngredientList([]);
    }
    if (
      ingredientList !== null &&
      item.targetWeight !== currentItem.targetWeight &&
      item.recipeId === currentItem.recipeId
    ) {
      const ingredientListTemp = ingredientList.map((ingredient) => ({
        ...ingredient,
        targetWeight: ingredient.ratio * item.targetWeight,
      }));
      setIngredientList(ingredientListTemp);
    }
    setCurrentItem(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    setIncompleteIngredientList(
      ingredientList === null ||
        !isEqual(totalAssignedWeight, item.targetWeight)
    );
    setOldestDelivery(
      ingredientList?.length &&
        ingredientList
          .map((current) =>
            current.assignedDeliveries.length
              ? current.assignedDeliveries.reduce(
                  (smallestAssigned, currentAssigned) =>
                    currentAssigned.bestBeforeDate <
                    smallestAssigned.bestBeforeDate
                      ? currentAssigned
                      : smallestAssigned
                )
              : null
          )
          .reduce(
            (smallest, current) =>
              smallest.bestBeforeDate >
              (current?.bestBeforeDate || current?.bestBeforeDate === 0
                ? current.bestBeforeDate
                : Infinity)
                ? current
                : smallest,
            { bestBeforeDate: Infinity }
          )
    );
  }, [ingredientList, totalAssignedWeight, item]);

  const handleFocus = (event) => event.target.select();

  const deleteIngredientTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() => {
          const ingredientListTemp = ingredientList.filter(
            (ingredient) => ingredient.ingredientID !== itemData.ingredientID
          );
          recalculateRatios(
            ingredientListTemp.filter(
              (ingredient) => ingredient.ingredientID !== itemData.ingredientID
            )
          );
        }}
      />
    );
  };

  const deleteDeliveryTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() => {
          const ingredientlistTemp = ingredientList.map((ingredient) => ({
            ...ingredient,
            assignedWeight: 0.0,
            assignedDeliveries: ingredient.assignedDeliveries.filter(
              (delivery) => delivery.deliveryID !== itemData.deliveryID
            ),
          }));
          setIngredientList(ingredientlistTemp);
        }}
      />
    );
  };

  const assignDeliveryButton = (itemData) => {
    return (
      <Button
        label="Assign"
        className=" p-button-sm p-button-outlined"
        onClick={() => setAction({ name: 'assignDelivery', data: itemData })}
      />
    );
  };

  const targetWeightDecimal = (rowData) => {
    return `${
      // eslint-disable-next-line no-nested-ternary
      typeof rowData.targetWeight === 'number'
        ? rowData.targetWeight.toFixed(3)
        : typeof rowData.targetWeight === 'string'
        ? parseFloat(rowData.targetWeight).toFixed(3)
        : '0.000'
    } kg`;
  };

  const assignedWeightTemplate = (rowData) => {
    if (isEqual(Number(rowData.targetWeight), Number(rowData.assignedWeight)))
      return (
        <span style={{ color: 'black' }}>
          {`${Number.parseFloat(rowData.assignedWeight).toFixed(3)} kg`}
        </span>
      );
    return (
      <span style={{ color: 'red' }}>
        {`${Number.parseFloat(rowData.assignedWeight).toFixed(3)} kg`}
      </span>
    );
  };

  const currentAmountDecimal = (rowData) => {
    return `${
      // eslint-disable-next-line no-nested-ternary
      typeof rowData.currentAmount === 'number'
        ? rowData.currentAmount.toFixed(3)
        : typeof rowData.currentAmount === 'string'
        ? parseFloat(rowData.currentAmount).toFixed(3)
        : '0.000'
    } kg`;
  };

  const assignedAmountDecimal = (rowData) => {
    return `${
      // eslint-disable-next-line no-nested-ternary
      typeof rowData.assignedAmount === 'number'
        ? rowData.assignedAmount.toFixed(3)
        : typeof rowData.assignedAmount === 'string'
        ? parseFloat(rowData.assignedAmount).toFixed(3)
        : '0.000'
    } kg`;
  };

  const assignDeliveryAmount = (e, deliveryID) => {
    const ingredientListTemp = [...ingredientList];

    // get indexes
    const ingredientListIndex = ingredientListTemp.findIndex((ingredient) =>
      ingredient.deliveries.some(
        (delivery) => delivery.deliveryID === deliveryID
      )
    );
    const deliveryIndex =
      ingredientListIndex !== -1 &&
      ingredientListTemp[ingredientListIndex].deliveries.findIndex(
        (delivery) => delivery.deliveryID === deliveryID
      );
    const assignedDeliveryIndex =
      ingredientListIndex !== -1 &&
      ingredientListTemp[ingredientListIndex].assignedDeliveries.findIndex(
        (delivery) => delivery.deliveryID === deliveryID
      );

    // calculate weight difference
    const availableAmount = Number(
      ingredientListTemp[ingredientListIndex].assignedDeliveries[
        assignedDeliveryIndex
      ].currentAmount
    );
    const assignedAmount = Number(
      ingredientListTemp[ingredientListIndex].assignedDeliveries[
        assignedDeliveryIndex
      ].assignedAmount
    );

    const newAmount = Number(e.value);
    const additionalAmount =
      // previous weight
      -Number(
        ingredientListTemp[ingredientListIndex].assignedDeliveries[
          assignedDeliveryIndex
        ].assignedAmount
      ) +
      // current weight with a max available weight cap
      (newAmount <= availableAmount + assignedAmount
        ? newAmount
        : availableAmount + assignedAmount);

    // adjusts weights in list
    ingredientListTemp[ingredientListIndex].assignedWeight += additionalAmount;
    ingredientListTemp[ingredientListIndex].deliveries[
      deliveryIndex
    ].remainingAmount -= additionalAmount;
    ingredientListTemp[ingredientListIndex].assignedDeliveries[
      assignedDeliveryIndex
    ].currentAmount -= additionalAmount;
    ingredientListTemp[ingredientListIndex].assignedDeliveries[
      assignedDeliveryIndex
    ].assignedAmount += additionalAmount;

    setIngredientList(ingredientListTemp);
  };

  const assignedAmountEditor = (rowData) => {
    return (
      <div className="p-inputgroup">
        <InputNumber
          value={rowData.value}
          onChange={(e) => assignDeliveryAmount(e, rowData.rowData.deliveryID)}
          mode="decimal"
          locale="de-DE"
          max={rowData.rowData.currentAmount + rowData.rowData.assignedAmount}
          min={0}
          minFractionDigits={1}
          maxFractionDigits={3}
          onFocus={handleFocus}
        />
        <span
          style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
          className="p-inputgroup-addon"
          title="Target food weight"
        >
          kg
        </span>
      </div>
    );
  };

  const ingredientTableColumns = [
    {
      key: 'ingredientId',
      header: 'Ingredient ',
      field: 'ingredientName',
      width: '20%',
    },
    {
      key: 'targetWeight',
      header: 'Target Weight',
      field: 'targetWeight',
      body: targetWeightDecimal,
      width: '20%',
    },
    {
      key: 'assignedWeight',
      header: 'Assigned Weight',
      body: assignedWeightTemplate,
      width: '20%',
    },
    {
      key: 'numberOfAssignedDeliveries',
      header: 'Assigned Deliveries',
      field: 'numberOfAssignedDeliveries',
      width: '20%',
    },
    { key: 'assign', body: assignDeliveryButton, center: true, width: '15%' },
    {
      key: 'delete',
      body: deleteIngredientTemplate,
      center: true,
      width: '5%',
    },
  ];

  const deliveriesTableColumns = [
    { header: 'Delivery ID', field: 'deliveryID' },
    {
      header: 'Available Amount',
      field: 'currentAmount',
      body: currentAmountDecimal,
    },
    {
      header: 'Assigned Amount',
      field: 'assignedAmount',
      editor: assignedAmountEditor,
      body: assignedAmountDecimal,
    },
    { key: 'delete', body: deleteDeliveryTemplate, center: true, width: '5%' },
  ];

  const fetchRecipeList = async () => {
    try {
      const recipeListJSON = await axiosConfig
        .get('/foodLogistics/recipes')
        .then((res) => res.data);
      if (recipeListJSON !== null) {
        const recipeListTemp = [];
        recipeListJSON.map(
          // eslint-disable-next-line array-callback-return
          ({ RecipeID, Name, Status, Notes, Ingredients }, key) => {
            if (Status === 'active' || Status === 'new') {
              recipeListTemp.push({
                key,
                label: Name,
                recipeName: Name,
                value: RecipeID,
                Status,
              });
            }
          }
        );
        const newRecipeIDTemp =
          1 + Math.max(...recipeListJSON.map((recipe) => recipe.RecipeID));
        recipeListTemp.push({
          label: 'New recipe',
          recipeName: 'New recipe',
          Status: 'new',
          value: newRecipeIDTemp,
        });
        setNewRecipeID(newRecipeIDTemp);
        setRecipeList(recipeListTemp);
        setItem({ ...item, recipeId: newRecipeIDTemp });
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching recipes List',
      });
    }
  };

  const fetchFoodTable = async () => {
    try {
      await axiosConfig.get('/foodLogistics/table').then((res) => {
        setFoodId(
          addPrefix(
            1 +
              Math.max(
                ...res.data.map((food) => {
                  return parseInt(food.foodId.substring(3), 10);
                })
              ),
            'FD',
            5
          )
        );
      });
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while food table list',
      });
    }
  };

  const fetchIngredientListForRecipe = async () => {
    try {
      if (
        (item !== initItem && currentItem === initItem) ||
        (item.recipeId !== null &&
          item.recipeId !== newRecipeID &&
          item.recipeId !== currentItem.recipeId)
      ) {
        const ID = item.recipeId;
        const ingredients = await axiosConfig
          .get('/foodLogistics/ingredientForRecipe', { params: { ID } })
          .then((res) => res.data);
        const newIngredientlist = ingredients.map((ingredient) => ({
          ...ingredient,
          targetWeight: ingredient.ratio * item.targetWeight,
          assignedWeight: 0,
          numberOfAssignedDeliveries: 0,
          assignedDeliveries: [],
        }));
        setIngredientList(newIngredientlist);
        setTotalAssignedWeight(0);
      }
    } catch (error) {
      console.log(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching recipes List',
      });
    }
  };

  const recipeOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.recipeName}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const assignedDeliveriesTable = (rowData) => {
    if (
      rowData === null ||
      !rowData.assignedDeliveries ||
      rowData.assignedDeliveries === null
    )
      return <span>Assign deliveries</span>;
    return (
      <div className="orders-subtable">
        <h6>Assigned Deliveries:</h6>
        <DataTable
          value={rowData.assignedDeliveries}
          size="small"
          emptyMessage="No assigned deliveries"
        >
          {deliveriesTableColumns.map(({ header, field, body, editor }) => (
            <Column
              header={header}
              field={field}
              body={body || null}
              editor={editor || null}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  const recalculateRatios = (ingredients) => {
    if (ingredients !== null && ingredients !== undefined) {
      const targetWeightTemp = ingredients.reduce(
        (sum, ingredient) => sum + Number(ingredient.targetWeight),
        0
      );
      const sumOfRatio = ingredients.reduce(
        (sum, ingredient) => sum + ingredient.ratio,
        0
      );
      let ingredientsTemp = ingredients;
      if (targetWeightTemp === 0) {
        if (
          ingredientsTemp.filter((ingredient) => ingredient.ratio === null)
            .length > 0 ||
          sumOfRatio === 0
        ) {
          // case on all ratios are zeros/ or some are null and all target weights are zero
          // set all ratios to equal
          const ratio = 1 / ingredientsTemp.length;
          ingredientsTemp = ingredientsTemp.map((ingredient) => ({
            ...ingredient,
            ratio,
          }));
        } else {
          // all target weights are zero but ratios are not
          // calculate ratios using sum of previous ratios
          ingredientsTemp = ingredientsTemp.map((ingredient) => ({
            ...ingredient,
            ratio: ingredient.ratio / sumOfRatio,
          }));
        }
      } else {
        // all target weights are not zeros
        // calculate ratios using target weight
        ingredientsTemp = ingredientsTemp.map((ingredient) => ({
          ...ingredient,
          ratio: ingredient.targetWeight / targetWeightTemp,
        }));
      }

      setIngredientList(ingredientsTemp);
      setCurrentItem({ ...item, recipeId: newRecipeID });
      setItem({
        ...item,
        recipeId: newRecipeID,
        targetWeight: targetWeightTemp,
      });
    } else {
      setItem({ ...item, recipeId: newRecipeID });
    }
  };

  const actionDialog = (actionData) => {
    switch (actionData.name) {
      case 'assignDelivery':
        return (
          <FoodDeliveryContext.Provider value={providerValue}>
            <FoodAssignDelivery
              data={actionData.data}
              display={Boolean(true)}
              onClose={() => setAction(null)}
              onCompletion={(ingredientListNew) => {
                setIngredientList(ingredientListNew);
              }}
            />
          </FoodDeliveryContext.Provider>
        );
      case 'addIngredient':
        return (
          <FoodDeliveryContext.Provider value={providerValue}>
            <FoodAddIngredients
              display={Boolean(true)}
              onClose={() => setAction(null)}
              onCompletion={(list) => recalculateRatios(list)}
            />
          </FoodDeliveryContext.Provider>
        );
      case 'confirmation':
        return (
          <FoodDeliveryContext.Provider value={providerValue}>
            <FoodCreateConfirmation
              data={{ ingredientList, item, newRecipeID, foodId }}
              display={Boolean(true)}
              onClose={() => setAction(null)}
              onCompletion={() => {
                onCompletion();
                onClose();
              }}
            />
          </FoodDeliveryContext.Provider>
        );
      default:
        return null;
    }
  };

  const assignedWeightAndTargetWeight = (targetWeight) => {
    if (isEqual(totalAssignedWeight, targetWeight))
      return (
        <span style={{ color: 'black' }}>
          {!isNaN(totalAssignedWeight) ? totalAssignedWeight.toFixed(3) : 0} kg
        </span>
      );
    return (
      <span style={{ color: 'red' }}>
        {!isNaN(totalAssignedWeight) ? totalAssignedWeight.toFixed(3) : 0} kg
      </span>
    );
  };

  return (
    <Dialog
      className="dialog-card"
      showHeader={false}
      style={{ width: '90vw' }}
      modal
      visible={display}
      onHide={onClose}
    >
      {action !== null && actionDialog(action)}
      <div className="mt-2 mb-5">
        <h2 className="flex align-items-center"> Create Food </h2>
      </div>
      <div className="grid">
        <Fieldset legend="General Setting">
          <div className="p-fluid formgrid grid">
            <div className="field col-3">
              <label htmlFor="foodName">Food Name</label>
              <InputText
                value={item.foodName}
                id="foodName"
                type="text"
                onChange={(e) =>
                  setItem({
                    ...item,
                    foodName: e.target.value.substring(0, 50),
                  })
                }
                onFocus={handleFocus}
              />
            </div>
            <div className="field col-3">
              <label htmlFor="foodId">Food ID</label>
              <InputText value={foodId} id="foodId" type="text" disabled />
            </div>
            <div className="field col-6">
              <label htmlFor="notes">Notes</label>
              <InputText
                value={item.comment}
                id="notes"
                type="text"
                onChange={(e) =>
                  setItem({
                    ...item,
                    comment: e.target.value.substring(0, 50),
                  })
                }
              />
            </div>
            <div className="field col-4">
              <label htmlFor="recipeId">Recipe</label>
              <Dropdown
                value={item ? item.recipeId : null}
                id="recipeId"
                options={recipeList}
                onChange={(e) => setItem({ ...item, recipeId: e.value })}
                placeholder="Select Recipe..."
                itemTemplate={recipeOptionTemplate}
              />
            </div>
            <div className="field col-4">
              <label htmlFor="bestbefore">Best Before</label>
              <Calendar
                dateFormat="dd.mm.yy"
                value={item.bestBefore}
                id="bestbefore"
                onChange={(e) =>
                  setItem({ ...item, bestBefore: e.target.value })
                }
                onFocus={handleFocus}
              />
            </div>
            <div className="field col-4">
              <label htmlFor="targetWeight">Target Weight</label>
              <div className="p-inputgroup">
                <InputNumber
                  id="targetWeight"
                  value={item.targetWeight}
                  onChange={(e) => setItem({ ...item, targetWeight: e.value })}
                  mode="decimal"
                  locale="de-DE"
                  minFractionDigits={1}
                  maxFractionDigits={3}
                  onFocus={handleFocus}
                />
                <span
                  style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
                  className="p-inputgroup-addon"
                  title="Target food weight"
                >
                  kg
                </span>
              </div>
            </div>
          </div>
        </Fieldset>
        <div className="field col-12">
          <h5>Assign Ingredient Deliveries</h5>
          <span>
            {`Target weight: ${item.targetWeight} kg | Assigned Weight: `}
          </span>
          <span /> {assignedWeightAndTargetWeight(item.targetWeight)}
          <div className="p-fluid formgrid grid5">
            <div className="field col-12">
              <div className="formgrid grid justify-content-center">
                <div className="field col-12">
                  <DataTable
                    value={ingredientList}
                    sortField="ingredientId"
                    sortOrder={-1}
                    emptyMessage="Add ingredients"
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    responsiveLayout="scroll"
                    rowExpansionTemplate={assignedDeliveriesTable}
                  >
                    <Column expander style={{ width: '3em' }} />
                    {ingredientTableColumns.map((column) => (
                      <Column
                        key={column.key}
                        field={column.field ? column.field : ''}
                        header={column.header ? column.header : ''}
                        sortable={
                          'sortable' in column ? column.sortable : false
                        }
                        style={{ minWidth: column.width }}
                        editor={
                          'editor' in column
                            ? (options) => column.editor(options)
                            : null
                        }
                        className="justify-content-center"
                        body={column.body ? column.body : null}
                      />
                    ))}
                  </DataTable>
                </div>
                <div className="field col-12">
                  <Button
                    className="p-button-outlined p-button-success p-button-rounded"
                    label="Add ingredient"
                    onClick={() => setAction({ name: 'addIngredient' })}
                  />
                </div>
                <div className="col-12">
                  {Math.floor(
                    (new Date(item.bestBefore) - new Date()) /
                      (1000 * 60 * 60 * 24)
                  ) > oldestDelivery?.bestBeforeDate ? (
                    <span style={{ color: 'orange' }}>
                      Warning: Please note, the best before date is greater than
                      the best before date of the oldest ingredient delivery:{' '}
                      {oldestDelivery?.deliveryID} at{' '}
                      {oldestDelivery?.bestBeforeDate < Infinity
                        ? formatDate(
                            new Date(
                              Math.ceil(
                                new Date().getTime() / MILLISECONDSINADAY +
                                  oldestDelivery.bestBeforeDate
                              ) * MILLISECONDSINADAY
                            )
                          )
                        : null}
                      . <br />
                    </span>
                  ) : null}
                  {inComplete ? (
                    <span style={{ color: 'red' }}>
                      Fill out all relevant fields in the settings.
                      <br />
                    </span>
                  ) : null}
                  {incompleteIngredientList ? (
                    <span style={{ color: 'red' }}> Assign ingredients.</span>
                  ) : null}
                </div>
                <div className="field col-6 mt-2 mb-5">
                  <Button
                    className="p-button-secondary p-button-rounded mr-2 mb-2"
                    label="Cancel"
                    onClick={() => onClose()}
                  />
                </div>
                <div className="field col-6 mt-2 mb-5">
                  <Button
                    label="Create Food"
                    // open confirm dialog and if confirmed, send the stackList to the server
                    onClick={() => setAction({ name: 'confirmation' })}
                    className="p-button-success  p-button-rounded"
                    // check if food and foodweight are filled for every box
                    disabled={inComplete || incompleteIngredientList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default CreateFood;
