/* eslint-disable react/prop-types */
import { useState, useContext, useEffect } from 'react';

import classNames from 'classnames';

import AppSidebar from 'elements/AppSidebar';
import AppTopbar from 'elements/AppTopbar';

import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom'; // Router für das Navigieren zwischen den versch. Unterseiten

import LandingPage from 'pages/LandingPage/LandingPage';
import Overview from 'pages/Overview/Overview';
import StablingMain from 'pages/Stabling/StablingMain';
import Reboxing from 'pages/Reboxing/Reboxing';
import Feeding from 'pages/Feeding/Feeding';
import BoxLogistics from 'pages/Logistics/BoxLogistics';
import EggLogistics from 'pages/Logistics/EggLogistics';
import FoodLogisticsMain from 'pages/Logistics/FoodLogistics/FoodLogisticsMain';
import Dashboard from 'pages/Dashboard/Dashboard';
import AnalyzeStack from 'pages/Analyze/AnalyeStack';
import Packaging from 'pages/Packaging/Packaging';
import UserProfileModal from 'elements/UserProfileModal';
import Sieving from 'pages/Sieving/Sieving';
import AuthContext from 'store/auth-context';

import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import './sass/main.scss';
import FarmSetup from 'pages/FarmSetup/FarmSetup';
import WorkPlan from 'pages/WorkPlan/WorkPlan';
import InpsectionPlans from 'pages/Inspection/InspectionPlans';
import Supplier from 'pages/Supplier/Supplier';
import Inspection from 'pages/Inspection/Inspection';
import {
  Positioning,
  fetchNumberOfPositioningTasks,
} from 'pages/Positioning/Positioning';
import Footer from 'elements/AppFooter';
import Customer from 'pages/Customer/Customer';
import { ToastContext } from 'utils/toastContextWrapper';
import UserSetup from 'pages/UserSetup/UserSetup';

function ProtectedRoute({ isAllowed, redirectPath = '/', children }) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />; // Redirect to login page if not logged in
  }
  // eslint-disable-next-line no-unneeded-ternary
  return children ? children : <Outlet />;
}

function App() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [userAuthVis, setUserAuthVis] = useState(false);
  const [task, setTask] = useState([]); // passes the task attributes to the worksteps in an array of json objects
  const [taskBatches, setTaskBatches] = useState([]); // passes the batchIds to the worksteps in an array of strings
  const [numberOfPositioningTasks, setNumberOfPositioningTasks] = useState();

  const authContext = useContext(AuthContext);
  const toast = useContext(ToastContext);

  const { user } = authContext;

  const wrapperClass = classNames('layout-wrapper', {
    'layout-static': true,
    'layout-static-sidebar-inactive': false,
    'p-input-filled': false,
  });

  useEffect(() => {
    fetchNumberOfPositioningTasks(toast).then((res) =>
      setNumberOfPositioningTasks(res)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={wrapperClass}>
      <Router>
        <AppTopbar
          onClick={(vis) => setSidebarVisible(vis)}
          onClickProfile={(vis) => setUserAuthVis(vis)}
        />

        {!!user && (
          <>
            <UserProfileModal
              visible={userAuthVis}
              onHide={() => setUserAuthVis(false)}
            />
            <AppSidebar
              visible={sidebarVisible}
              onHide={() => setSidebarVisible(false)}
              numberOfPositioningTasks={numberOfPositioningTasks}
            />
          </>
        )}

        <div className="layout-main-container">
          <div className="layout-main">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route element={<ProtectedRoute isAllowed={!!user} />}>
                <Route
                  path="/sieving"
                  element={<Sieving task={task} taskBatches={taskBatches} />}
                />
                <Route
                  path="/overview"
                  element={
                    <Overview
                      setTask={setTask}
                      setTaskBatches={setTaskBatches}
                    />
                  }
                />
                <Route path="/stabling" element={<StablingMain />} />
                <Route path="/reboxing" element={<Reboxing />} />
                <Route
                  path="/feeding"
                  element={<Feeding task={task} taskBatches={taskBatches} />}
                />
                <Route path="/boxlogistics" element={<BoxLogistics />} />
                <Route path="/egglogistics" element={<EggLogistics />} />
                <Route path="/foodlogistics" element={<FoodLogisticsMain />} />
                <Route path="/positioning" element={<Positioning />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/analyze_stack" element={<AnalyzeStack />} />
                <Route
                  path="/packaging"
                  element={<Packaging task={task} taskBatches={taskBatches} />}
                />
                <Route path="/customer" element={<Customer />} />
                <Route
                  path="/inspection"
                  element={<Inspection task={task} taskBatches={taskBatches} />}
                />
                <Route
                  path="/farmsetup"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.permissions.includes(3)}
                      redirectPath="/overview"
                    >
                      <FarmSetup />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/workplan"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.permissions.includes(3)}
                      redirectPath="/overview"
                    >
                      <WorkPlan />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/inspectionplan"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.permissions.includes(3)}
                      redirectPath="/overview"
                    >
                      <InpsectionPlans />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/supplier"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.permissions.includes(3)}
                      redirectPath="/overview"
                    >
                      <Supplier />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tenantsetup"
                  element={
                    <ProtectedRoute
                      isAllowed={!!user && user.permissions.includes(4)}
                      redirectPath="/overview"
                    >
                      <UserSetup />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </div>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
