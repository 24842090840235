/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import axiosConfig from 'utils/axiosConfig';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { FaCarrot } from 'react-icons/fa';
import { InputNumber } from 'primereact/inputnumber';
import { Fieldset } from 'primereact/fieldset';
import { Calendar } from 'primereact/calendar';

import BoxQRScan from 'elements/BoxQRScan';
import FoodQRScan, {
  checkFood,
  handleExternalFoodScan,
} from 'elements/FoodQRScan';
import { ToastContext, ToastSeverity } from 'utils/toastContextWrapper';

import { Dropdown } from 'primereact/dropdown';
import { checkInputMissingExceptCommentAndWorkStepId } from 'utils/utils';
import { Chip } from 'primereact/chip';
import AuthContext from 'store/auth-context';
import QRCodeTextField from 'elements/QRCodeTextField';
import { getBoxIdsFromBatchIds } from 'utils/boxUtils';
import { formatDate } from 'pages/Supplier/Supplier';

const addPrefix = (id, prefix, numLeadingZeroes) =>
  `${prefix}-${id.toString().padStart(numLeadingZeroes, '0')}`;

function Feeding({ task, taskBatches }) {
  const navigate = useNavigate();
  const toast = useContext(ToastContext);
  const MASK = 'aa-99999';
  const [item, setItem] = useState({
    foodWeight:
      task?.find((attribute) => attribute.attribute === 'foodWeight')?.value ||
      0,
    recipeId: task?.find((attribute) => attribute.attribute === 'recipeId')
      ?.value
      ? addPrefix(
          task.find((attribute) => attribute.attribute === 'recipeId').value,
          'RE',
          5
        )
      : null,
    workStepId:
      task?.find((attribute) => attribute.attribute === 'workStepId')?.value ||
      null,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [element, setElement] = useState(null);
  const [recipeList, setRecipeList] = useState([]);
  const [stackList, setStackList] = useState([]);
  const [foodList, setFoodList] = useState([]);
  const [showDialog, setShowDialog] = useState('');
  const [boxes, setBoxes] = useState([]);
  const [incompleteBatches, setIncompleteBatches] = useState([]);
  const { user } = useContext(AuthContext);
  const resultFunctionDict = {
    FD: (scanResult) =>
      setItem({
        ...item,
        foodId: scanResult,
        recipeId: foodList.find(({ foodId }) => foodId === scanResult).recipeId,
      }),
    BX: (scanResult) => setBoxes([...(boxes || []), ...scanResult]),
  };

  const fetchRecipeList = async () => {
    try {
      const recipeListJSON = await axiosConfig
        .get('/foodLogistics/recipes')
        .then((res) => res.data);
      if (recipeListJSON !== null) {
        setRecipeList(
          recipeListJSON
            .filter(({ Status }) => Status === 'active' || Status === 'new')
            .map(
              // eslint-disable-next-line array-callback-return
              ({ RecipeID, Name }, key) => ({
                key,
                label: Name,
                value: addPrefix(RecipeID, 'RE', 5),
              })
            )
        );
      }
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching recipes List',
      });
    }
  };

  const fetchFoodList = async () => {
    try {
      const foodListJSON = await axiosConfig
        .get('/foodLogistics/table')
        .then((res) => res.data);
      setFoodList(foodListJSON);
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: 'Error while fetching Food List',
      });
    }
  };

  const checkUnequalTreatment = (list) => {
    if (!list.length) return true;
    // reduce list to list [{batchId, conditions: str, equal: boolean}]
    return list
      .reduce((accumulator, currentValue) => {
        const index = accumulator.findIndex(
          ({ batchId }) => batchId === currentValue.batchId
        );
        if (index !== -1) {
          accumulator[index].equal =
            JSON.stringify({
              recipeId: currentValue.recipeId,
              foodWeight: currentValue.foodWeight,
            }) === accumulator[index].conditions
              ? accumulator[index].equal
              : false;
        } else {
          accumulator.push({
            batchId: currentValue.batchId,
            equal: true,
            conditions: JSON.stringify({
              recipeId: currentValue.recipeId,
              foodWeight: currentValue.foodWeight,
            }),
          });
        }
        return accumulator;
      }, [])
      .filter(({ equal }) => !equal)
      .map(({ batchId }) => batchId);
  };

  useEffect(() => {
    fetchRecipeList();
    fetchFoodList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hook for modifying the boxIdNotList when the stackList or batchList changes
  useEffect(() => {
    // get box ids from batch Ids
    if (taskBatches?.length) {
      (async () => {
        const boxesTemp = await getBoxIdsFromBatchIds(taskBatches, toast);
        setBoxes(boxesTemp);
      })();
    }

    stackList?.length && setIncompleteBatches(checkUnequalTreatment(stackList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackList, taskBatches]);

  const numberEditor = (options) => (
    <InputNumber
      value={options.rowData[options.field]}
      onChange={(e) => {
        const newStackList = [...stackList];
        newStackList[
          newStackList.findIndex((box) => box.boxId === options.rowData.boxId)
        ][options.field] = e.value;
        setStackList(newStackList);
      }}
      decimal
      locale="de-DE"
      minFractionDigits={1}
      onFocus={handleFocus}
    />
  );

  const handleFocus = (event) => event.target.select();

  const dropdownEditor = (options) => {
    return (
      <Dropdown
        value={options.rowData.foodId}
        id="foodid"
        options={foodList}
        onChange={(e) => {
          const newStackList = [...stackList];
          const index = newStackList.findIndex(
            (box) => box.boxId === options.rowData.boxId
          );
          newStackList[index].foodId = e.value;
          newStackList[index].recipeId = foodList.find(
            ({ foodId }) => foodId === e.value
          ).recipeId;
          setStackList(newStackList);
        }}
        placeholder
        optionLabel="foodId"
        optionValue="foodId"
        valueTemplate={selectedFoodTemplate}
        itemTemplate={foodOptionTemplate}
      />
    );
  };

  const commentEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.rowData.comment}
        onChange={(e) => {
          const newStackList = [...stackList];
          const rowIndex = newStackList.findIndex(
            (box) => box.boxId === options.rowData.boxId
          );
          newStackList[rowIndex].comment = e.target.value;
          setStackList(newStackList);
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const calanderEditorTemplate = (options) => {
    return (
      <Calendar
        dateFormat="dd.mm.yy"
        value={options.rowData.completedAt || new Date()}
        onChange={(e) => {
          const newStackList = [...stackList];
          const rowIndex = newStackList.findIndex(
            (box) => box.completedAt === options.rowData.completedAt
          );
          newStackList[rowIndex].completedAt = e.value;
          setStackList(newStackList);
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const commentBodyTemplate = (options) => {
    // return check mark if comment is not empty, otherwise return plus sign
    return options.comment !== '' ? (
      <i className="pi pi-check" />
    ) : (
      <Button
        icon="pi pi-plus-circle"
        className=" p-button-sm p-button-outlined"
      />
    );
  };

  const deleteBoxTemplate = (itemData) => {
    return (
      <Button
        icon="pi pi-trash"
        className=" p-button-sm p-button-outlined"
        onClick={() =>
          setStackList(stackList.filter((box) => box.boxId !== itemData.boxId))
        }
      />
    );
  };

  const dateTemplate = (rowData) =>
    rowData?.completedAt ? formatDate(new Date(rowData.completedAt)) : null;

  const stackTableColumns = [
    { key: 'boxId', header: 'Box ID', field: 'boxId', sortable: true },
    {
      key: 'foodId',
      header: 'Food ID',
      field: 'foodId',
      sortable: true,
      editor: dropdownEditor,
    },
    {
      key: 'foodWeight',
      header: 'Food Weight',
      field: 'foodWeight',
      editor: numberEditor,
    },
    {
      key: 'completedAt',
      header: 'Completed At',
      field: 'completedAt',
      editor: calanderEditorTemplate,
      body: dateTemplate,
      center: true,
    },
    {
      key: 'comment',
      header: 'Comment',
      field: 'comment',
      editor: commentEditor,
      body: commentBodyTemplate,
      center: true,
    },
    { key: 'delete', body: deleteBoxTemplate, center: true, header: 'Delete' },
  ];

  const submitList = async () => {
    try {
      // send list of boxes to backend // TODO: add comments
      await axiosConfig.post('/feeding', {
        batchList: [{ stackList }],
        email: user.email,
        workStepId: item?.workStepId,
      });

      toast.pushToast({
        severity: ToastSeverity.SUCCESS,
        detail: 'Successful Feeding!',
      });

      setStackList([]);
      setIncompleteBatches([]);
      setShowDialog('');
      navigate('/overview');
      // Go back to overview page
    } catch (error) {
      console.error(error);
      toast.pushToast({
        severity: ToastSeverity.ERROR,
        detail: error.response.status + error.response.data,
      });
    }
  };

  const selectedFoodTemplate = (option) => {
    if (option) {
      return <span>{option.foodId}</span>;
    }

    return 'Select Food ID';
  };

  const foodOptionTemplate = (rowData) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>{rowData.foodId}</div>
        <span className={`charge-badge charge-badge-${rowData.Status} `}>
          {rowData.Status}
        </span>
      </div>
    );
  };

  const addToList = async (scanResults) => {
    const toastMsgList = [
      await checkFood(item?.foodId, [], true, true, item?.recipeId || null),
    ];

    toastMsgList.map((toastMsg) =>
      toastMsg ? toast.pushToast(toastMsg) : null
    );

    if (toastMsgList.some((toastMsg) => toastMsg)) {
      return false;
    }

    const newItems = scanResults.map((scanResult) => {
      return {
        ...item,
        boxId: scanResult.boxId,
        batchId: scanResult.batchId,
      };
    });

    setStackList(
      !stackList.length
        ? newItems.map((newItem, idx) => ({
            ...newItem,
            positionInStack: idx + 1,
          }))
        : [
            ...stackList,
            ...newItems.map((newItem, idx) => ({
              ...newItem,
              positionInStack: stackList.length + idx + 1,
            })),
          ]
    );
    setActiveIndex(1);
    setBoxes([]);
    return true;
  };

  const firstPage = () => {
    return (
      <div className="formgrid grid p-fluid align-items-end">
        <div className="field col-3">
          <label htmlFor="recipeId">Recipe ID</label>
          <Dropdown
            value={item.recipeId}
            id="recipeId"
            options={recipeList}
            onChange={(e) => {
              setItem({ ...item, recipeId: e.target.value, foodId: null });
            }}
            disabled={stackList?.length}
            placeholder="Select Recipe"
          />
        </div>
        <div className="field col-1">
          <Button
            className="p-button-outlined"
            icon="pi pi-times"
            onClick={() => setItem({ ...item, recipeId: null })}
          />
        </div>
        <div className="field col-3">
          <label htmlFor="foodid">Food ID</label>
          <Dropdown
            value={item?.foodId}
            id="foodid"
            options={
              !item?.recipeId
                ? foodList?.filter(({ remainingAmount }) => remainingAmount > 0)
                : foodList?.filter(
                    (food) =>
                      food.recipeId === item?.recipeId &&
                      food.remainingAmount > 0
                  )
            }
            onChange={(e) =>
              handleExternalFoodScan(
                e.target.value,
                MASK,
                'FD',
                toast,
                (scanResult) =>
                  setItem({
                    ...item,
                    foodId: scanResult,
                    recipeId: foodList.find(
                      ({ foodId }) => foodId === scanResult
                    ).recipeId,
                  }),
                null,
                item?.recipeId || null
              )
            }
            placeholder
            editable
            onFocus={handleFocus}
            optionLabel="foodId"
            optionValue="foodId"
            valueTemplate={selectedFoodTemplate}
            itemTemplate={foodOptionTemplate}
          />
        </div>
        <div className="field col-1">
          <Button
            className=" p-button-outlined"
            onClick={() => setShowDialog('food')}
            icon="pi pi-qrcode"
          />
        </div>
        {showDialog === 'food' && (
          <FoodQRScan
            onResult={(scanResult) => setItem({ ...item, foodId: scanResult })}
            onClose={() => {
              setShowDialog('');
            }}
            display={Boolean(showDialog)}
            foodShouldBeActive
            recipeId={item?.recipeId || null}
          />
        )}
        <div className="field col-4 ">
          <label htmlFor="foodWeight">
            Food Weight per Box (Available:{' '}
            {item?.foodId
              ? Array.isArray(foodList) &&
                foodList.find(({ foodId }) => item.foodId === foodId)
                  .remainingAmount
              : 0}{' '}
            kg)
          </label>
          <div className="p-inputgroup">
            <InputNumber
              id="foodWeight"
              value={item?.foodWeight}
              onChange={(e) =>
                setItem({
                  ...item,
                  foodWeight: Math.min(
                    e.value,
                    item?.foodId
                      ? Array.isArray(foodList) &&
                          foodList.find(({ foodId }) => item.foodId === foodId)
                            .remainingAmount
                      : 0
                  ),
                })
              }
              mode="decimal"
              max={
                item?.foodId
                  ? Array.isArray(foodList) &&
                    foodList.find(({ foodId }) => item.foodId === foodId)
                      .remainingAmount
                  : 0
              }
              locale="de-DE"
              minFractionDigits={1}
              onFocus={handleFocus}
              disabled={!item.foodId}
            />
            <span
              style={{ backgroundColor: '#8d99a5', color: '#FFFFFF' }}
              className="p-inputgroup-addon"
              title="Weight per Feeding"
            >
              kg
            </span>
          </div>
        </div>
        <div className=" field col-3">
          <label htmlFor="completedAt">Completed At</label>
          <Calendar
            dateFormat="dd.mm.yy"
            id="completedAt"
            value={item?.completedAt || new Date()}
            onChange={(e) => setItem({ ...item, completedAt: e.value })}
          />
        </div>
        <div className=" field col-9">
          <label htmlFor="name1">Comment</label>
          <InputText
            id="name1"
            type="text"
            value={item.comment}
            // add a symbol depending if the comment is empty or not
            onChange={(e) =>
              setItem({
                ...item,
                comment: e.target.value,
                commentSymbol: e.target.value ? '✓' : '✗',
              })
            }
          />
        </div>
        {/* <div className="field col-2" /> */}

        <div className="field col-12">
          <div
            className="grid grid-nogutter"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className=" col-3" style={{ 'margin-right': '10px' }}>
              <Button
                className="p-button-rounded p-button-outlined "
                label="Manually add boxes"
                icon="pi pi-qrcode"
                onClick={() => setShowDialog('box')}
              />
            </div>
            <div className=" col-3">
              <Button
                className="p-button-rounded p-button-outlined p-button-success"
                label="Continue"
                onClick={() => addToList(boxes)}
                disabled={!item.foodId || !item.foodWeight || !boxes?.length}
              />
            </div>
          </div>
          <div className="mt-3">
            <Fieldset
              legend={`Number of selected boxes: ${boxes?.length}`}
              toggleable
              collapsed
            >
              {boxes?.length ? (
                <div className="col-12">
                  {boxes.map((box) => (
                    <Chip
                      style={{
                        marginRight: '10px',
                        marginTop: '5px',
                      }}
                      label={box.boxId}
                    />
                  ))}
                </div>
              ) : null}
            </Fieldset>
          </div>
        </div>
      </div>
    );
  };

  const compareFoodAmounts = (stackListTemp, foodListTemp) => {
    // reduce to weights
    const foodWeights = stackListTemp.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.foodId]) {
        accumulator[currentValue.foodId] = currentValue.foodWeight;
      } else {
        accumulator[currentValue.foodId] += currentValue.foodWeight;
      }
      return accumulator;
    }, {});

    return foodListTemp.map(({ foodId, remainingAmount, Status }) => ({
      foodId,
      remainingAmount,
      Status,
      foodWeight: foodWeights[foodId],
      weightEqualOrLess: foodWeights[foodId]
        ? Math.round(foodWeights[foodId] * 1000) / 1000 <=
          Math.round(remainingAmount * 1000) / 1000
        : true,
    }));
  };

  const secondPage = () => {
    return (
      <div className="formgrid grid justify-content-center">
        {showDialog === 'confirm' && (
          <ConfirmDialog
            visible={showDialog === 'confirm'}
            onHide={() => setShowDialog('')}
            message="Finish Feeding?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={() => submitList()}
          />
        )}
        <div className="field col-12">
          <DataTable
            value={stackList}
            showGridlines
            scrollable
            size="small"
            scrollHeight="50vh"
            sortField="boxId"
            sortOrder={-1}
            editMode="cell"
          >
            {stackTableColumns.map((column) => (
              <Column
                key={column.key}
                field={column.field ? column.field : ''}
                header={column.header ? column.header : ''}
                sortable={'sortable' in column ? column.sortable : false}
                editor={
                  'editor' in column
                    ? (options) => column.editor(options)
                    : null
                }
                className="justify-content-center"
                // className={column.center ? 'justify-content-center' : ''}
                body={column.body ? column.body : null}
              />
            ))}
          </DataTable>
        </div>
        <div className="col-12">
          {compareFoodAmounts(stackList, foodList).some(
            (food) => !food.weightEqualOrLess
          ) && (
            <div className="field col-10 col-offset-1">
              <span style={{ color: 'orange' }}>
                Please make sure the total food weight assigned to boxes are
                below the available weight for the food
                <br />
                {compareFoodAmounts(stackList, foodList)
                  .filter(({ weightEqualOrLess }) => !weightEqualOrLess)
                  .map(({ foodId, foodWeight, remainingAmount }, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={key}>
                      Food Id: {foodId} has an assigned weight of {foodWeight}{' '}
                      kg with only {remainingAmount} kg available
                    </li>
                  ))}
              </span>
            </div>
          )}
        </div>
        {stackList.length && incompleteBatches.length ? (
          <div className="field col-10 col-offset-1">
            <span style={{ color: 'orange' }}>
              Unequal treatment will result in splitting of batch
              {incompleteBatches.length === 1 ? null : 'es'}{' '}
              {incompleteBatches.join(', ')}.
            </span>
          </div>
        ) : null}
        <div className="field col-4 col-offset-2 mt-2">
          <Button
            label="Finish Feeding"
            // open confirm dialog and if confirmed, send the stackList to the server
            onClick={() => setShowDialog('confirm')}
            className="p-button-success  p-button-rounded mr-2 mb-2"
            // check if food and foodweight are filled for every box
            disabled={
              !stackList.length ||
              stackList.some((box) =>
                checkInputMissingExceptCommentAndWorkStepId(box)
              ) ||
              compareFoodAmounts(stackList, foodList).some(
                (food) => !food.weightEqualOrLess
              )
            }
          />
        </div>
      </div>
    );
  };

  const INTERVALLENGTH = 100;
  useEffect(() => {
    const interval = setInterval(() => {
      if (element && document.activeElement.tagName.toUpperCase() !== 'INPUT')
        element.focus();
    }, INTERVALLENGTH);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [element]);

  return (
    <div>
      <div className="fixed">
        <QRCodeTextField
          MASK={MASK}
          resultFunctionDict={resultFunctionDict}
          boxIdNotList={[]}
          boxes={boxes}
          setElement={(inputElement) => setElement(inputElement)}
          boxesShouldBeActive
          boxesShouldExist
        />
      </div>
      <div className="main-card main-card-content">
        {showDialog === 'box' && (
          <BoxQRScan
            onResult={(scanResult) => setBoxes(scanResult)}
            itemListInit={boxes.length ? boxes : []}
            onClose={() => {
              setShowDialog('');
            }}
            display={Boolean(showDialog)}
            boxesShouldBeActive
            boxesShouldExist
            scanModeList={['series', 'stack', 'batch']}
            // List of every boxId located in each stack
            batchesShouldBeComplete
            boxIdNotList={
              stackList.length ? stackList.map(({ boxId }) => boxId) : []
            }
          />
        )}
        <div className="mt-2 mb-5">
          <h2 className="flex align-items-center">
            <FaCarrot className="mr-3" /> Feeding
          </h2>
        </div>
        <Accordion
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <AccordionTab header="Add General Information">
            {firstPage()}
          </AccordionTab>
          <AccordionTab header="Box Overview" disabled={!stackList.length}>
            {secondPage()}
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}

Feeding.propTypes = {
  task: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  taskBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Feeding;
