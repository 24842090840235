/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { useState, useContext } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { NavLink } from 'react-router-dom';

import AuthContext from 'store/auth-context';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import classNames from 'classnames';

import {
  FaCarrot,
  FaBoxes,
  FaClipboardList,
  FaUserTag,
  FaCrosshairs,
  FaBox,
  FaUserTie,
  FaSearch,
  FaCog,
} from 'react-icons/fa';
import { GiMagnifyingGlass, GiHandTruck } from 'react-icons/gi';
import {
  // BsGraphUp,
  BsStack,
  BsEgg,
  BsArrowRepeat,
  BsJournalBookmark,
  BsInboxesFill,
} from 'react-icons/bs';
import { BiCog } from 'react-icons/bi';
// import { AiOutlineTool } from 'react-icons/ai';
import { RiComputerLine, RiInboxArchiveLine } from 'react-icons/ri';
import {
  MdOutlineBackHand,
  MdOutlineGrain,
  MdOutlineHouseSiding,
  MdOutlineAnalytics,
} from 'react-icons/md';
import { CgSmartHomeWashMachine } from 'react-icons/cg';

function AppSubmenu(props) {
  const [activeIndex, setActiveIndex] = useState(null);

  const onMenuItemClick = (event, item, index) => {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item,
      });
    }
    props.onHide(false);
    return true;
  };

  const onKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.target.click();
    }
  };

  const renderLinkContent = (item) => {
    const submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon" />
    );
    const badge = item.badge && <Badge value={item.badge} />;

    return (
      <>
        {item.icon}
        <span>{item.label}</span>
        {submenuIcon}
        {badge || null}
        <Ripple />
      </>
    );
  };

  const renderLink = (item, i) => {
    const content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          aria-label={item.label}
          onKeyDown={onKeyDown}
          role="menuitem"
          className={classNames('p-ripple', { 'p-disabled': item.disabled })}
          // className="router-link-active router-link-exact-active"
          to={item.to}
          onClick={(e) => onMenuItemClick(e, item, i)}
          exact="true"
          target={item.target}
        >
          {content}
        </NavLink>
      );
    }
    return (
      <a
        tabIndex="0"
        aria-label={item.label}
        onKeyDown={onKeyDown}
        role="menuitem"
        href={item.url}
        className="p-ripple p-disabled"
        onClick={(e) => onMenuItemClick(e, item, i)}
        target={item.target}
      >
        {content}
      </a>
    );
  };

  const items =
    props.items &&
    props.items.map((item, i) => {
      const active = activeIndex === i;
      const styleClass = classNames(item.badgeStyleClass, {
        'layout-menuitem-category': props.root,
        'active-menuitem': active && !item.to,
      });

      if (props.root) {
        return (
          <li className={styleClass} key={(`rootitem-`, i)} role="none">
            {props.root === true && (
              <>
                <div
                  className="layout-menuitem-root-text"
                  aria-label={item.label}
                >
                  {item.icon}
                  {/* <i className={item.icon} /> */}
                  <span style={{ marginLeft: '0.5rem' }}>{item.label}</span>
                </div>
                <AppSubmenu
                  items={item.items}
                  onMenuItemClick={props.onMenuItemClick}
                  onHide={props.onHide}
                />
              </>
            )}
          </li>
        );
      }
      return (
        <li className={styleClass} key={(`item-`, i)} role="none">
          {renderLink(item, i)}
          <CSSTransition
            classNames="layout-submenu-wrapper"
            timeout={{ enter: 1000, exit: 450 }}
            in={active}
            unmountOnExit
          >
            <AppSubmenu
              items={item.items}
              onMenuItemClick={props.onMenuItemClick}
              onHide={props.onHide}
            />
          </CSSTransition>
        </li>
      );
    });

  return items ? (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  ) : null;
}

// eslint-disable-next-line react/prop-types
function AppSidebar(props) {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const menu = [
    {
      label: 'Action',
      icon: <MdOutlineBackHand />,
      items: [
        {
          label: 'Overview',
          icon: <RiComputerLine />,
          to: '/overview',
        },
        { label: 'Stabling', icon: <RiInboxArchiveLine />, to: '/stabling' },
        { label: 'Inspection', icon: <GiMagnifyingGlass />, to: '/inspection' },
        { label: 'Reboxing', icon: <BsInboxesFill />, to: '/reboxing' },
        { label: 'Feeding', icon: <FaCarrot />, to: '/feeding' },
        { label: 'Sieving', icon: <CgSmartHomeWashMachine />, to: '/sieving' },
        {
          label: 'Positioning',
          icon: <FaCrosshairs />,
          to: '/positioning',
          // badge: props.numberOfPositioningTasks,
        },
        { label: 'Packaging', icon: <FaBox />, to: '/packaging' },
      ],
    },
    {
      label: 'Analyze',
      icon: <MdOutlineAnalytics />,
      items: [
        //     { label: 'Dashboard', icon: <BsGraphUp />, to: '/dashboard' },
        //     { label: 'Cost Evaluation', icon: <FaBars /> },
        //     { label: 'Optimization', icon: <AiOutlineTool /> },
        { label: 'Stack', icon: <BsStack />, to: '/analyze_stack' },
      ],
    },
    {
      label: 'Warehouse',
      icon: <GiHandTruck />,
      items: [
        {
          label: 'Egg Deliveries',
          icon: <BsEgg />,
          to: '/egglogistics',
        },
        {
          label: 'Food',
          icon: <MdOutlineGrain />,
          to: '/foodlogistics',
        },
        { label: 'Boxes', icon: <FaBoxes />, to: '/boxlogistics' },
      ],
    },
    {
      label: 'Setup',
      icon: <BiCog />,
      items: [
        ...[
          {
            label: 'Work Plans',
            icon: <FaClipboardList />,
            to: '/workPlan',
            disabled: !user.permissions.includes(3),
          },
          {
            label: 'Inspection Plans',
            icon: <FaSearch />,
            to: '/inspectionplan',
            disabled: !user.permissions.includes(3),
          },
          {
            label: 'Suppliers',
            icon: <FaUserTag />,
            to: '/supplier',
            disabled: !user.permissions.includes(3),
          },
          { label: 'Customers', icon: <FaUserTie />, to: '/customer' },
          { label: 'Breeding Cycle', icon: <BsArrowRepeat /> },
          { label: 'Recipes', icon: <BsJournalBookmark /> },
        ],
        ...(user.permissions.includes(3)
          ? [
              {
                label: 'Farm Setup',
                icon: <MdOutlineHouseSiding />,
                to: '/farmSetup',
              },
            ]
          : []),
        ...(user.permissions.includes(4)
          ? [{ label: 'Tenant Setup', icon: <FaCog />, to: '/tenantsetup' }]
          : []),
      ],
    },
  ];

  return (
    <Sidebar
      visible={props.visible}
      onHide={() => props.onHide(false)}
      className="layout-sidebar"
      showCloseIcon={false}
      dismissable
    >
      <AppSubmenu
        items={menu}
        className="layout-menu"
        onMenuItemClick={props.onMenuItemClick}
        root
        role="menu"
        onHide={() => props.onHide(false)}
      />
    </Sidebar>
  );
}

export default AppSidebar;
